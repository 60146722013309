<div (furyClickOutside)="onClickOutside()" class="toolbar-notifications" fxFlex="grow" fxLayout="row" fxLayoutAlign="start center">

    <button (click)="toggleDropdown()" [class.open]="isOpen" class="button" fxFlex="grow" mat-button type="button">
      <mat-icon [matBadgeHidden]="totalCount === 0" [matBadge]="totalCount" class="icon">notifications_active</mat-icon>
    </button>

  <div [class.open]="isOpen" class="dropdown">

    <fury-card>

      <!-- HEADER -->
      <fury-card-header class="dropdown-header">

        <fury-card-header-heading>Notifications</fury-card-header-heading>

        <fury-card-header-subheading>
          You have {{ totalCount }} new notifications.
        </fury-card-header-subheading>

        <fury-card-header-actions>

          <button mat-button type="button" (click)="onShowAll()">
            Show all
          </button>

        </fury-card-header-actions>

      </fury-card-header>
      <!-- END OF HEADER -->

      <!-- NOTIFICATIONS -->
      <fury-card-content class="dropdown-card-content">

        <fury-scrollbar class="dropdown-content">

          <div fxLayout="column">

            <ng-container *ngFor="let notification of notifications; let last = last">

              <div (click)="markAsRead(notification, $event)"
                [@listFade]
                [class.read]="notification.IsRead === 'Yes'" [ngClass]="notification.colorClass"
                class="notification" fxLayout="row"
                fxLayoutAlign="start center" matRipple>

                <!-- <mat-icon class="icon">{{ notification.Category === 'Request for More Information' ? 'info' : 'message' }}</mat-icon> -->

                <span class="icon" *ngIf="notification.Category === 'Request for More Information'">⚠</span>
                <span class="icon" *ngIf="notification.Category !== 'Request for More Information'">📨</span>

                <div class="label" fxLayout="column">
                  <div class="name">{{ notification.Title }}</div>
                  <div class="time">{{ notification.EnteredOn | date : 'EEE, dd MMM, yyyy H:mm:ss' }}</div>
                </div>

                <span fxFlex></span>

                <!-- <button (click)="dismiss(notification, $event)" mat-icon-button type="button">
                  <mat-icon class="close">close</mat-icon>
                </button> -->

              </div>

              <mat-divider *ngIf="!last"></mat-divider>

            </ng-container>

          </div>

          <div *ngIf="notifications.length === 0" [@listFade] class="empty">
            All fine! No notifications right now.
          </div>

        </fury-scrollbar>

      </fury-card-content>
      <!-- END OF NOTIFICATIONS -->

      <fury-card-actions (click)="markAllAsRead()" class="dropdown-footer" matRipple>
        Mark all as read
      </fury-card-actions>

    </fury-card>

  </div>

</div>
