<div class="toolbar" fxLayout="row" fxLayoutAlign="space-between">
  <div fxLayout="row" fxLayoutAlign="start center">
    <fury-toolbar-sidenav-mobile-toggle (openSidenav)="openSidenav.emit()"></fury-toolbar-sidenav-mobile-toggle>

    <div [fxShow.gt-sm]="topNavigation$ | async" [fxShow.xs]="false" [routerLink]="['/']" class="toolbar-logo" fxShow>
      
      <img src="assets/img/pdpo-logoo.jpg" width="80" height="40" alt="PDPO">
    </div>

    <!-- <fury-toolbar-search-bar fxFlex="500px" fxHide fxShow.gt-md></fury-toolbar-search-bar> -->
  </div>

  <div fxLayout="row">

    <fury-toolbar-fullscreen-toggle class="toolbar-button"></fury-toolbar-fullscreen-toggle>

    <fury-toolbar-search fxHide fxShow.gt-sm fxHide.gt-md class="toolbar-button"></fury-toolbar-search>

    <fury-toolbar-notifications class="toolbar-button"></fury-toolbar-notifications>

    <fury-toolbar-theming class="toolbar-button"></fury-toolbar-theming>

    <fury-toolbar-user class="toolbar-button"></fury-toolbar-user>

  </div>
</div>

