<main>
    <button mat-button class="close-window-btn" (click)="onCloseWindow()">
        <mat-icon>clear</mat-icon>
    </button>    

    <article
        style="width: 100%; margin: 0px 0px 30px; position: relative;"
        id="letterBodyContainer">

        <button 
            *ngIf="!downloading"
            mat-button
            color="primary"
            [disabled]="downloading"
            class="download-button"
            (click)="onDownloadLetter()">
            <div class="button-label">
                <mat-icon>cloud_download</mat-icon>
                <span>Download </span>
            </div>
        </button>
   

        <div class="image-wrapper" [ngStyle]="{'margin': downloading ? '10px auto 40px' : '30px auto 40px', 'width': '200px', 'overflow': 'hidden'}">
            <img src="./assets/icons/Final-logos-28.png" style="width: 100%; " alt="">
        </div>        
        
        <section class="letter-body" style="padding: 0px 40px 45px;">

            <div>
                <h1 style="font-weight: 700; font-size: 14px; text-transform: uppercase; text-align: center; color: #000; margin-top: 0px;margin: -30px 0px 25px 0px;">
                    Breaches
                </h1>
    
                <h3 style="font-weight: 700; letter-spacing: 1px; font-size: 14px; text-transform: uppercase; text-align: left; color: #000; margin: 0px 0px -10px 0px;">
                    Organisation
                </h3>
    
                <div style="margin: 15px 0px; display: grid; grid-template-columns: repeat(auto-fit,minmax(200px, 1fr)); gap: 20px 25px;">
    
                    <p style="font-size: 11px; line-height: 14px; font-weight: 400; color: #000; text-align: left; display: flex; flex-direction: column; gap: 3px; margin: 0px;">
                        <span>Name of Organisation</span>
                        <b style="font-weight: 600;">{{ data.row.organisation_name }}</b>
                    </p>
    
                    <p *ngIf="data.row.URSBNumber" style="font-size: 11px; line-height: 14px; font-weight: 400; color: #000; text-align: left; display: flex; flex-direction: column; gap: 3px; margin: 0px;">
                        <span>Registration Number</span>
                        <b style="font-weight: 600;">{{ data.row.RegTrackingNo }}</b>
                    </p>
    
                    <p style="font-size: 11px; line-height: 14px; font-weight: 400; color: #000; text-align: left; display: flex; flex-direction: column; gap: 3px; margin: 0px;">
                        <span>Organisation Telephone Number</span>
                        <b style="font-weight: 600;">{{ data.row.PhoneNumber }}</b>
                    </p>
    
                    <p style="font-size: 11px; line-height: 14px; font-weight: 400; color: #000; text-align: left; display: flex; flex-direction: column; gap: 3px; margin: 0px;">
                        <span>Organisation Email Address</span>
                        <b style="font-weight: 600;">{{ data.row.email }}</b>
                    </p>
    
                    <p style="font-size: 11px; line-height: 14px; font-weight: 400; color: #000; text-align: left; display: flex; flex-direction: column; gap: 3px; margin: 0px;">
                        <span>Date Of Incidence</span>
                        <b style="font-weight: 600;">{{ data.row.IncidentDate }}</b>
                    </p>
    
                    <p style="grid-column: 1/-1;font-size: 11px; line-height: 14px; font-weight: 400; color: #000; text-align: left; display: flex; flex-direction: column; gap: 3px; margin: 0px;">
                        <span>Likely Causes of the breach</span>
                    </p>
                    <div *ngFor="let item of data.row.BreachCauses">
                        <mat-checkbox [checked]="true" style="font-size: 11px; line-height: 14px; font-weight: 400; color: #000; text-align: left; display: flex; flex-direction: column; gap: 3px; margin: 0px;">{{ item.CauseName }}</mat-checkbox>
                    </div>
    
                </div>
            </div>

            <div>
                <hr style="margin: 40px 0px; width: 100%; border: 0px;border-bottom: 1px solid #ebeced;" />

                <h3 style="font-weight: 700; letter-spacing: 1px; font-size: 14px; text-transform: uppercase; text-align: left; color: #000; margin: 0px 0px -10px 0px;">
                    Data Subject Affected by breach
                </h3>

                <div style="margin: 15px 0px; display: grid; grid-template-columns: repeat(auto-fit,minmax(200px, 1fr)); gap: 20px 25px;">

                    <p style="font-size: 11px; line-height: 14px; font-weight: 400; color: #000; text-align: left; display: flex; flex-direction: column; gap: 3px; margin: 0px;">
                        <span>Categories of Data Subject Affected by breach</span>
                        <b style="font-weight: 600;">{{ data.row.CategoriesOfDataSubjectAffected }}</b>
                        
                    </p>

                    <p style="font-size: 11px; line-height: 14px; font-weight: 400; color: #000; text-align: left; display: flex; flex-direction: column; gap: 3px; margin: 0px;">
                        <span>Number of data Subjects</span>
                        <b style="font-weight: 600;">{{ data.row.NumberOfDataSubjects }}</b>
                    </p>

                    <p style="font-size: 11px; line-height: 14px; font-weight: 400; color: #000; text-align: left; display: flex; flex-direction: column; gap: 3px; margin: 0px;">
                        <span>Total Number of Records held</span>
                        <b style="font-weight: 600;">{{ data.row.NumberOfRecordsHeld }}</b>
                    </p>

                    <p style="font-size: 11px; line-height: 14px; font-weight: 400; color: #000; text-align: left; display: flex; flex-direction: column; gap: 3px; margin: 0px;">
                        <span>Details On Likely causes of breaches</span>
                        <b style="font-weight: 600;"> {{ data.row.DetailedDataBreachCause }} </b>
                    </p>

                    <p style="font-size: 11px; line-height: 14px; font-weight: 400; color: #000; text-align: left; display: flex; flex-direction: column; gap: 3px; margin: 0px;">
                        <span>Remedial Actions taken or proposed</span>
                        <b style="font-weight: 600;">{{ data.row.RemedialActionsTaken }}</b>
                    </p>

                </div>
            </div>

            <div>

                <hr style="margin: 40px 0px; width: 100%; border: 0px;border-bottom: 1px solid #ebeced;" />

                <h3 style="font-weight: 700; letter-spacing: 1px; font-size: 14px; text-transform: uppercase; text-align: left; color: #000; margin: 0px 0px -10px 0px;">
                    Personal Data Affected
                </h3>

                <div>
                    <p style="line-height: 14px; font-size: 11px; font-weight: 400; color: #000; text-align: left; display: flex; flex-direction: column; gap: 3px; margin: 0px;">
                        
                        <b style="font-weight: 600;">{{ data.row.PersonalDataAffected }}</b>
                    </p>
                </div>

            </div>

            <div>
                <hr style="margin: 40px 0px; width: 100%; border: 0px;border-bottom: 1px solid #ebeced;" />

                <h3 style="font-weight: 700; letter-spacing: 1px; font-size: 14px; text-transform: uppercase; text-align: left; color: #000; margin: 0px 0px -10px 0px;">
                    Containment & Recovery
                </h3>

                <div style="margin: 15px 0px; display: grid; grid-template-columns: repeat(auto-fit,minmax(200px, 1fr)); gap: 20px 25px;">

                    <p *ngif="data.row.IsActionTakenToMinimize == 1"  style="grid-column: 1/-1; font-size: 11px; line-height: 14px; font-weight: 400; color: #000; text-align: left; display: flex; flex-direction: column; gap: 3px; margin: 0px;">
                        <mat-checkbox [checked]="true"> Has any action been taken to minimise/mitigate the effect on the affected individuals? </mat-checkbox>                
                    </p>

                    <p style="grid-column: 1/-1; font-size: 11px; line-height: 14px; font-weight: 400; color: #000; text-align: left; display: flex; flex-direction: column; gap: 3px; margin: 0px;">
                        <span>Details Of Action Taken</span>

                        <b style="font-weight: 600;margin-left: 3px;text-align: justify;">
                            <span> {{ data.row.ActionTakenToMinimizeDetails}}</span>
                        </b>                        
                    </p>
                </div>
            </div>

            <div *ngIf="downloading" class="html2pdf__page-break"></div>

            <div>
                <hr *ngIf="!downloading" style="margin: 40px 0px 20px; width: 100%; border: 0px;border-bottom: 1px solid #ebeced;" />

                <h3 style="padding-top: 20px; font-weight: 700; letter-spacing: 1px; font-size: 14px; text-transform: uppercase; text-align: left; color: #000; margin: 0px 0px -10px 0px;">
                    Purpose & Retention
                </h3>

                <div style="margin: 15px 0px; display: grid; grid-template-columns: repeat(auto-fit,minmax(200px, 1fr)); gap: 20px 25px;">

                    <p *ngIf="data.row.IsDataPlacedAtRiskRecovered == 1"   style="grid-column: 1/-1; font-size: 11px; line-height: 14px; font-weight: 400; color: #000; text-align: left; display: flex; flex-direction: column; gap: 3px; margin: 0px;">
                        <mat-checkbox [checked]="true"> Has the data placed at risk now been recovered? </mat-checkbox>
                        
                    </p>

                    <p style="font-size: 11px; line-height: 14px; font-weight: 400; color: #000; text-align: left; display: flex; flex-direction: column; gap: 3px; margin: 0px;">
                        <span>Data recovery details</span>
                        <b style="font-weight: 600;">{{ data.row.DataPlacedAtRiskRecoveredDetails }}</b>
                    </p>

                    <p *ngIf="data.row.dateOfRecovery" style="font-size: 11px; line-height: 14px; font-weight: 400; color: #000; text-align: left; display: flex; flex-direction: column; gap: 3px; margin: 0px;">
                        <span>Date of data recovery</span>
                        <b style="font-weight: 600;">{{ data.row.DataRecoveryDate }}</b>
                    </p>

                    <p *ngIf="data.row.preventionSteps" style="font-size: 11px; line-height: 14px; font-weight: 400; color: #000; text-align: left; display: flex; flex-direction: column; gap: 3px; margin: 0px;">
                        <span>Prevention Steps</span>
                        <b style="font-weight: 600;">{{ data.row.PreventiveStepsToPreventRecurrence }}</b>
                    </p>
                </div>   
            </div>


            <div>

                <div>
                    <hr style="margin: 40px 0px; width: 100%; border: 0px;border-bottom: 1px solid #ebeced;" />

                    <h3 style="font-weight: 700; letter-spacing: 1px; font-size: 14px; text-transform: uppercase; text-align: left; color: #000; margin: 0px 0px -10px 0px;">
                        Breach Consequences
                    </h3><br>
                    <div *ngFor="let item of data.row.PotentialConsequences">
                        <mat-radio-group color="primary">
                            <mat-radio-button [checked] = "true" value="Yes">{{item.BreachDescription}}</mat-radio-button>   
                        </mat-radio-group>    
                    </div>   
                </div><br>

                <div>
                    <h3 style="font-weight: 700; letter-spacing: 1px; font-size: 14px; text-transform: uppercase; text-align: left; color: #000; margin: 0px 0px -10px 0px;">
                        Potential Harms
                    </h3><br>

                    <div *ngFor="let item of data.row.PotentialHarms">
                        <mat-radio-group color="primary">
                            <mat-radio-button [checked] = "true" value="Yes">{{item.HarmDescription}}</mat-radio-button>
                        </mat-radio-group>
                    </div>

                </div>
            </div>   

            <div style="margin: 70px 0px 15px; display: grid; grid-template-columns: repeat(auto-fit,minmax(200px, 1fr)); gap: 20px 25px;">

                <p style="font-size: 12px;line-height: 25px;font-weight: 400;color: #000;text-align: left;margin: 0px;">
                    I, 
                    <i style="  border-bottom: 1px dashed; padding: 0px 40px; font-weight: 600; color: #000;"> {{ data.row.organisation_name }} </i>
                    certify that the above information is correct and complete and here by report a Breach as 
                    data collector/ data processor / data controller (This shall be based on th category selected) 
                    under the data Protection and Privacy Act.
                </p>

            </div>               

        </section>

    </article>
</main>