<main [ngClass]="{'status-true': data.status === true, 'status-false': data.status === false}">
    <div class="mat-progress-bar-container">
        <mat-progress-bar *ngIf="processing" mode="indeterminate"></mat-progress-bar>
    </div>

    <header>
        <h1 [innerHTML]="data.title"></h1>

        <button mat-button (click)="onCloseDialog()">
            <mat-icon>clear</mat-icon>
        </button>
    </header>
    
    <section>
       <h1>Categories</h1>
        <div  *ngFor="let category of data.category">
            <div  *ngFor="let cat of category">
            <p>{{cat}}</p>
            </div>
        </div>
        <h1>Level</h1>
        <div>
            
            <p>{{data.level}}</p>
            
        </div>
    </section>   
</main>