<div class="mat-progress-bar-container" >
    <mat-progress-bar color="primary" *ngIf="processing" mode="indeterminate"></mat-progress-bar>
</div>

<main class="scrollbar">
    <header class="main-header">
        <div>
            <button 
                (click)="onCloseWindow()" 
                mat-button 
                matTooltip="Close the window">
                <mat-icon>west</mat-icon>
            </button>

            <h1>All System Notifications</h1>
        </div>

    </header>

    <section>

        <header>
            <button mat-button (click)="onFetch()" [disabled]="processing" matTooltip="Reload the table">
                <mat-icon>sync</mat-icon>
            </button>

            <mat-form-field>
                <mat-label>Search</mat-label>
                <mat-icon matPrefix>search</mat-icon>
                <input matInput [disabled]="processing" #input (keyup)="applyFilter($event.target.value)">
                <mat-icon *ngIf="input.value" (click)="input.value = null;applyFilter('')" class="clear-mat-icon">clear</mat-icon>
            </mat-form-field>
        </header>

        <div class="table-container">
            <table 
                mat-table 
                matSortDisableClear="true"
                [dataSource]="dataSource"
                matSort>

                <!-- Count Column -->
                <ng-container matColumnDef="count">
                    <th mat-header-cell *matHeaderCellDef> # </th>
                    <td mat-cell *matCellDef="let row"> 

                        <fury-card-content class="dropdown-card-content">

                            <fury-scrollbar class="dropdown-content">
                    
                              <div fxLayout="column">
                    
                                <ng-container>
                    
                                  <div (click)="markAsRead(row, $event)"
                                    [@listFade]
                                    [class.read]="row.IsRead === 'Yes'" [ngClass]="row.colorClass"
                                    class="notification" fxLayout="row"
                                    fxLayoutAlign="start center" matRipple>
                    
                                    <!-- <mat-icon class="icon">{{ row.Category === 'Request for Information' ? 'info' : 'message' }}</mat-icon> -->
                    
                                    <span class="icon" *ngIf="row.Category === 'Request for More Information'">⚠</span>
                                    <span class="icon" *ngIf="row.Category !== 'Request for More Information'">📨</span>                    

                                    <div class="label" fxLayout="column">
                                      <div class="name">{{ row.Title }}</div>
                                      <div class="time">{{ row.EnteredOn | date : 'EEE, dd MMM, yyyy H:mm:ss' }}</div>
                                    </div>
                    
                                    <span fxFlex></span>
                    
                                    <!-- <button (click)="dismiss(row, $event)" mat-icon-button type="button">
                                      <mat-icon class="close">close</mat-icon>
                                    </button> -->
                    
                                  </div>
                    
                                  <mat-divider *ngIf="!last"></mat-divider>
                    
                                </ng-container>
                    
                              </div>
                    
                              <div *ngIf="notifications.length === 0" [@listFade] class="empty">
                                All fine! No notifications right now.
                              </div>
                    
                            </fury-scrollbar>
                    
                          </fury-card-content>

                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>                

        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

    </section>

</main>